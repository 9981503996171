.groupMemberClickShow{
    width: 10vh;
    height: 10vh;
    background-color: #f8010138;
}

.groupMemberClickStyle1 {
    display: flex;
    flex-wrap: wrap; /* 複数行に折り返す */
    justify-content:center; /* 要素を均等に配置 */
    text-align: center;
    align-items: center;
    padding: 0%;
  }

  .memberTextEng1 {
    font-family: "Holtwood One SC";
    font-size: 2rem;
    padding: 0.1rem;
    margin-bottom: 0.2rem;
    background: linear-gradient(transparent 90%, #16b5dd 10%);
    font-weight: 100;
    margin-top: 5vh;
    margin-bottom: 0%;
}


.memberTextJpa1 {
    font-size: 1rem;
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 2vh;
}

.returnButtonStyle {
    cursor: pointer;
    width: 300px;
    height: 50px;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    background-color: #f7f7f7;
    color: #030303;
    font-size: 20px;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 3vh;
  }