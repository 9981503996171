/*矢印が右に移動する*/
.btnarrow4{
    /*矢印と下線の基点とするためrelativeを指定*/
  position: relative;
    /*形状*/
    display: inline-block;
    text-decoration: none;
    outline: none;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 4vw;
    font-weight: 600;
    font-style: normal;
}

/*矢印と下線の形状*/
.btnarrow4::before{
    content: '';
    /*絶対配置で下線の位置を決める*/
  position: absolute;
    bottom:-8px;
    left:15%;
    /*下線の形状*/    
    width: 85%;
    height: 1px;
  background:#333;
    /*アニメーションの指定*/
    transition: all .3s;
}

.btnarrow4::after{
    content: '';
    /*絶対配置で矢印の位置を決める*/
  position: absolute;
    bottom:-3px;
    right:0;
    /*矢印の形状*/    
    width: 15px;
    height:1px;
  background:#333;
    transform: rotate(35deg);
    /*アニメーションの指定*/
    transition: all .3s;
}

/*hoverした際の移動*/
.btnarrow4:hover::before{
    left:20%;
}

.btnarrow4:hover::after{
    right:-5%;
}



.waricanCreateButton {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    border: 1px solid #030303;
    box-sizing: border-box;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    color: #030303;
    background-color: #f7f853;
    outline: none;
    margin-left: 40px;
  }

  .waricanCreateIcon{
    color: #030303;
    fill: #030303;
    width: 14px;
    height: 14px;
    font-size: 14px;
  }