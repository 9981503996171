.commentForm {
    width: 180px;
    height: 130px;
    border: 2px solid #505050;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: "Roboto";
    word-wrap: break-word;
    word-break: break-all;
    outline: none;
    margin-left: 10px;
  }
  