.inputSearch {
    width: 238px;
    height: 36px;
    padding: 0px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 24px;
    outline: none;
  }
  