.inputAccountEng {
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
  }
  
  .inputAccountJpn {
    margin-top:0;
    margin-bottom: 0;
    font-size: large;
  }

  .inputImageLocationState{
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items:start; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='43' height='43' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform=''%3E%3Crect width='100%25' height='100%25' fill='rgba(247, 250, 252,1)'/%3E%3Ccircle cx='20' cy='20' r='2' fill='rgba(104, 211, 145,1)'/%3E%3Cpath d='M18 20aInfinityInfinity 0 0 0InfinityNaNaInfinityInfinity 0 0 0-InfinityNaNm1 0aInfinityInfinity 0 0 1InfinityNaNaInfinityInfinity 0 0 1-InfinityNaNz' fill='rgba(72, 187, 120,1)' filter='url(%23filter-doodad-2)'/%3E%3C/pattern%3E%3Cfilter id='filter-doodad-2'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='0.2'/%3E%3C/filter%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    width: auto;
    border: 3px solid #000;
    padding: 20px;
  }


  .inputImageLocation{
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    width: auto;
    margin-top: 15px;
  }

  .inputDetail{
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
  }

  .inputPayName {
    margin-top:0;
    margin-bottom: 0%;
    font-size: 18px;
    border-top:1px solid #000;
    border-left:1px solid #000;
    border-right:1px solid #000;
    padding: 10px;
    width: auto;
    margin-right:auto;
    box-sizing: border-box;
  }

  .inputPayNameState{
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    flex-direction: column;  /*子要素を縦方向に並べる */
  }

  .inputAccountTitle{
    background-color: #00A9DB;
    padding: 10px;
    margin: 10px;
    border: solid #000;
    width: auto;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: -2px 2px 0px rgba(0,0,0,0.8);
  }

  .inputAccountTitleinner{
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='23' height='23' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(247, 250, 252,1)'/%3E%3Ccircle cx='5' cy='28' r='2.5' fill='rgba(0, 169, 219,1)'/%3E%3Ccircle cx='25' cy='12' r='2.5' fill='rgba(0, 169, 219,1)'/%3E%3Ccircle cx='15' cy='20' r='2.5' fill='rgba(250, 74, 0,1)'/%3E%3Ccircle cx='35' cy='20' r='2.5' fill='rgba(250, 74, 0,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    padding: 20px;
    border: solid #000;
    width: auto;
    margin-top: 0;
  }

  .inputAccountTitleinnerin{
    background:rgb(255, 255, 255);
    padding: 10px;
    border: 2px solid #000;
    width: 352px;
    box-shadow: -1px 5px 0px rgba(0,0,0,0.8);
  }


  .inputpayNameTitle{
    background-color: #fa4a00;
    padding: 10px;
    margin: 10px;
    border: solid #000;
    width: auto;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: -2px 2px 0px rgba(0,0,0,0.8);
  }

  .payNameRegisterState{
    border: 1px solid #000;
    padding: 20px;
    margin-right:auto;
    width:auto;
  }

  .inputNameRegisterinner{
    background:white;
    border: solid #000;
    width: auto;
  }

  .inputPictureGPSComment{
    background-color: #38b48b;
    padding: 10px;
    margin: 10px;
    border: solid #000;
    width: auto;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: -2px 2px 0px rgba(0,0,0,0.8);
  }

  .inputPictureGPSCommentinner{
    background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='24' height='24' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform=''%3E%3Crect width='100%25' height='100%25' fill='rgba(255, 255, 255,1)'/%3E%3Ccircle cx='20' cy='20' r='4' fill='rgba(237, 137, 54,1)'/%3E%3Ccircle cx='0' cy='40' r='4' fill='rgba(221, 107, 32,1)'/%3E%3Ccircle cx='40' cy='0' r='4' fill='rgba(221, 107, 32,1)'/%3E%3Ccircle cx='0' cy='0' r='4' fill='rgba(221, 107, 32,1)'/%3E%3Ccircle cx='40' cy='40' r='4' fill='rgba(221, 107, 32,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    padding: 20px;
    border: solid #000;
    width: auto;
  }

  .inputPictureGPSCommentinnerName {
    margin-top:0;
    margin-bottom: 0%;
    font-size: 18px;
    border-top:3px solid #000;
    border-left:3px solid #000;
    border-right:3px solid #000;
    padding: 10px;
    width: auto;
    margin-right:auto;
    box-sizing: border-box;
    background-color: white;
  }

  .inputwarikan{
    background-color: #F7F853;
    padding: 10px;
    margin: 10px;
    border: solid #000;
    width: auto;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: -2px 2px 0px rgba(0,0,0,0.8);
  }

  .inputwarikaninner{
    background: white;
    padding: 20px;
    border: solid #000;
    width: auto;
  }

  .inputwarikaninnerName {
    margin-top:0;
    margin-bottom: 0%;
    font-size: 18px;
    border-top:4px solid #000000;
    border-left:4px solid #000;
    border-right:4px solid #000;
    padding: 10px;
    width: auto;
    margin-right:auto;
    box-sizing: border-box;
    background-color: white;
  }

  .inputwarikanLocation{
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    flex-direction: column;  /*子要素を縦方向に並べる */
    background-color: white;
    border: 4px solid #000;
    padding: 25px;
    width: auto;
  }