.themeEngTop1{
    font-family: "Holtwood One SC";
    font-size: 2.5rem;
    font-weight:lighter;
    margin-bottom: 0vh;
    margin-top: 0vh;
  }
  
  .tukaikatafont{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.675rem;
    font-weight: 600;
    font-style: normal;
    margin-top: 0vh;
  }

  .backgroundColorf6f6f6{
    background-color: #f6f6f6;
  }

  .border333333{
    border: 1px solid #333333;
  }

  .backgroundColorfffff{
    background-color: #ffffff;
  }

  