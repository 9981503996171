  /*リストに追加されたものを4つずつ横並びで表示させる*/
.group-members {
    display: flex;
    flex-wrap: wrap; /* 複数行に折り返す */
    justify-content: center; /* 要素を均等に配置 */
  }

  .groupMembersNameRation {
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
    height: 50%;
    vertical-align:  middle; 
  }
  
  .group-memberCreate {
    margin: 2vh; /* 要素間の余白を設定 */
    text-align: center; /* テキストを左揃えにする */
    width: 8vh;
    height: 8vh;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style:normal;
    border: 1px solid #000000;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.8);
    padding: 0.5vh;
  }

  .deleteButtonPosition{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    left: 40px;
    bottom: 50px;
  }

  .deletebutton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid #030303;
    box-sizing: border-box;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    color: #030303;
    background-color: #f98920;
  }
  
  .deletebuttonicon {
    color: #030303;
    fill: #030303;
    width: 60px;
    height: 60px;
    font-size: 50px;
  }

.memberNameInputText{
    width: 60vw;
    height: 12vw;
    padding: 0px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style:normal;
    text-align: center;
    outline: none;
    }
  
.memberRationInputText {
    width: auto;
    height: 40px;
    padding: 0px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 24px;
    outline: none;
    margin-right: 1vw;
    font-family: "Zen Maru Gothic";
    }