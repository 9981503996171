.footer {
    background-color: #333333;
    display: flex; /* 要素を横並びに配置 */
    flex-direction: column; /* 子要素を縦方向に並べる */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    align-items: center; /* 要素を垂直方向に中央揃え */
    padding: 4vh;
    padding-bottom: 60px;
  }

.footerChildrenState{
    display: flex; /* 要素を横並びに配置 */
    align-items: center; /* 要素を垂直方向に中央揃え */
    margin:0.5vh;
}

.footerChildrenState2{
    display: flex; /* 要素を横並びに配置 */
    align-items: center; /* 要素を垂直方向に中央揃え */
}

.margin2vh{
    margin-left: 2vh;
    margin-right: 2vh;
}

.margin4vh{
    margin-left: 4vh;
    margin-right: 4vh;
}

.fontcolor{
    color: #f3f3f3;
}

.fontsize07{
    font-size: 0.7rem;
}

.marginright{
    margin-right: 2vw;
}

.border-top{
    border-top: 1px solid #f3f3f3;
    width: 100%;
}

a {
    text-decoration: none;
  }