.groupTitleEditEng {
    font-family: "Holtwood One SC";
    font-size: 1.675rem;
    font-weight: 600;
    font-style: normal;
    border-bottom: 1px solid #000;
}


.groupTitleEditJpn {
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style: normal;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
}

.groupNameShowEdit{
    font-size: 2rem;
    font-family: "Zen Maru Gothic";
    width: auto;
    height: auto;
    background-color: #f9f3a3;
    border-radius: 26px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 25%;
    margin-left: 25%;
    justify-content: center;
}

.groupNameShow1{
    background-color: #ae2020;
}

.height20vw{
    height: 22.5vw;
}

.heightauto{
    height:max-content;
    display: flex;
    flex: 1;
}

.magrin-bottom0vh{
    margin-bottom: 0vh;
}

.magrin-left0vh{
    margin-left: 0vh;
}

.groupNameState{
    display: flex; /* �e�v�f���t���b�N�X�R���e�i�ɂ��� */
    align-items: center; /* �q�v�f�𐅕������ɒ��������ɂ��� */
    justify-content:center; /* �q�v�f�𐂒������ɒ��������ɂ��� */
  }

  .text-aligncenter{
    text-align: center;
  }

.border-bottom {
    border: 1px solid #000;
}


.background16b5dd{
    background-color: #16b5dd;
}

.backgroundffffff{
    background-color: #ffffff;
}