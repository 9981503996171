.horizontal-list {
	overflow-x: auto;
	white-space: nowrap;
	width: auto;
	margin: 0%;
	padding: auto;
}
li.item1 {
	display: inline-block;
	width: 50%;
	height: 400px;
	margin: 16px;
	font-size: 48px;
	color: #fff;
	background-color: #fa4a00;
    
}	
li.item2 {
	display: inline-block;
	width: 50%;
	height: 400px;
	margin: 16px;
	font-size: 48px;
	color: #fff;
	background-color: #00A9DB;
}	
li.item3 {
	display: inline-block;
	width: 50%;
	height: 400px;
	margin: 16px;
	font-size: 48px;
	color: #fff;
	background-color: #38b48b;
}	
li.item4 {
	display: inline-block;
	width: 50%;
	height: 400px;
	margin: 16px;
	font-size: 48px;
	color: #fff;
	background-color: #F7F853;
}	