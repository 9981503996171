.createButtonTop {
    cursor: pointer;
    width: 300px;
    height: 50px;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    background-color: #f7f7f7;
    color: #030303;
    font-size: 20px;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 3vh;
  }
  