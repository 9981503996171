.groupPageEng {
    font-family: "Holtwood One SC";
    font-size: 2.5rem;
    font-weight:lighter;
    margin-bottom: 0vh;
    margin-top: 0vh;
  }
  
  .groupPageJpn {
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.675rem;
    font-weight: 600;
    font-style: normal;
    margin-top: 0vh;
  }
  
  .groupPageLine{
    margin-top: 0;
    margin-bottom: 0;
    width: 315px;
    height: 1px;
    background-color: #030303;
    border-radius: 2px;
  }

.groupNameshow1{
  border-radius: 10px;
  background: #ffffff;/* background: #00a8db6f元の色 */
  justify-content:center; /* 子要素を垂直方向に中央揃えにする */
  width:100%;
  height: auto;
  border: 1px solid #000;
}

.groupPageName1{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  margin: 0vh;
}

 .sceneName1 {
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  margin: 0vh;
}

.mannnakanioku{
  padding: 2vh;
}

/* 割り勘箇所*/
.margin-top2vh{
  margin-top: 2vh;
}

.margin-left2vh{
  margin-left: 2vh;
}

.border-top3{
  border-top: 2px solid #626262;
}

.blackbox{
  width: 5vw;
  height: 5vw;
  background-color: #000;
  margin-left: -50%;
}

.tatesen{
  border: 2px solid#000;
  height: 8vw;
}

.tatesenState{
  display: flex; /* 要素を横並びに並べる */
  align-items:center; /* 要素を垂直方向に中央揃え */
  margin-top: 0%;
}

.tatesenStateYoko{
  display: flex; /* 要素を横並びに並べる */
  justify-content:space-evenly; /* 要素間のスペースを均等に分配 */
}

/* 割りの箇所の本文 */
.warikanbackground{
  background-color: #f9f9f9;
  opacity: 0.8;
  background-size: 20px 20px;
  background-image:  repeating-linear-gradient(0deg, #38b48b62, #38b48b62 1px, #f9f9f9 1px, #f9f9f9);
  margin-top: 0%;
}

.speechBubble {
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
  padding: 2vh;
  border: 2px solid #000000;
  background-color: #ffffff;
  text-align: center;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  margin-top: 0vh;
  color: #000000;
  width: 80%;
  height: auto;
}

.speechBubble::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  border-style: solid;
  border-width: 20px 10px 0 10px;
  border-color: #000000 transparent transparent;
  translate: -50% 100%;
}

.speechBubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  border-style: solid;
  border-width: 15.5px 7.8px 0 7.8px;
  border-color: #ffffff transparent transparent;
  translate: -50% 100%;
}

.backgroundColorcf9f9f9{
  background-color: #f9f9f9;
}

.background-bottom2vh{
  padding-bottom: 5vh;
}

.themeEngTop1{
  font-family: "Holtwood One SC";
  font-size: 2.5rem;
  font-weight:lighter;
  margin-bottom: 0vh;
}

.setumeifont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  margin-top: 0vh;
}

.pandding2vh{
  padding: 2vh;
}

.panddingop2vh{
  padding-top: 2vh;
}

.slant-line-05 {
  transform: skewY(-0.5deg);
}

.slant-line05 {
  transform: skewY(0.5deg);
}

/*割り勘金額*/

.backgroundColorececea{
  background-color: #ececea;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: solid 2px #000000;
}

.group-page-header {
    display: flex; /* 要素を横並びに並べる */
    justify-content:center; /* 要素間のスペースを均等に分配 */
    align-items: center; /* 要素を垂直方向に中央揃え */
    gap: 20px;
    width: 80%;
    background-color: rgb(255, 255, 255);
    height: 16vh;
    margin-top: 2vh;
  }

.waricanThemeEng{
    margin-top: 5vh;
    margin-bottom: 0;
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    background: linear-gradient(transparent 60%, #7ce75f 60%);
    font-weight: bold;
  }

.waricanThemeJpn{
    font-size: 1.5rem;
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 20;
  }

  .howmachThemeEng
  {
    margin-top: 10vh;
    margin-bottom: 0;
    font-family: "Holtwood One SC";
    font-size: 2.5rem;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
    background: linear-gradient(transparent 60%, #faaa29 60%);
    font-weight: bold;
  }

  .howmachThemeJpn{
    font-size: 1.5rem;
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 20;
  }

.crateGroupNameTitleBox{
    position: relative;
    padding:0.25em 1em;
}
.crateGroupNameTitleBox:before,.box19:after{ 
    content:'';
    width: 20px;
    height: 30px;
    position: absolute;
    display: inline-block;
}
.crateGroupNameTitleBox:before{
    border-left: solid 1px #5767bf;
    border-top: solid 1px #5767bf;
    top:0;
    left: 0;
}
.crateGroupNameTitleBox:after{
    border-right: solid 1px #5767bf;
    border-bottom: solid 1px #5767bf;
    bottom:0;
    right: 0;
}
.crateGroupNameTitleBox p {
    margin: 0; 
    padding: 0;
}

/*========= 流体シェイプを動かす ===============*/
.crateGroupNameTitleBoxFluid {
    width: 10vw;/*横幅*/
    height: 10vh;/*縦幅*/
    background:#dcdab6;/*背景色*/
    animation: fluidrotate 20s ease 0s infinite;/*アニメーションの設定*/
    margin-left: 4vh;
    margin-right: 4vh;
  }
  
  @keyframes fluidrotate {  
      
  0%, 100% {
      border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
      border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
      border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
      border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
      border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
      border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
      border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
  0% {
    background-color: #fff100; /* 最初の色 */
  }
  33% {
    background-color: #ff0000; /* 中間色1 */
  }
  66% {
    background-color: #00ffff; /* 中間色2 */
  }
  100% {
    background-color: #fff100; /* 最後の色 */
  }
}

  /*========= グループページの名前 ===============*/
  .a {
    position: relative;
    background: #ffffff;
    border-bottom: 0.5px solid #333333;
    height: auto;
    display: flex; /* 親要素をフレックスコンテナにする */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
  }

  .groupName_GroupPage {
    position: relative;
    background: #ffffff;
    opacity: 0.9;
    display: flex; /* 親要素をフレックスコンテナにする */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
    height: auto;
  }
  
  .groupName_GroupPage:before {
    position: absolute;
    top: -7px;
    left: -7px;
    width: 100%;
    height: 100%;
    content: '';
    border: 4px solid #000;
  }
  
  .flex{
    display: flex;
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
  }

.groupNameshow{
  position: relative;
  border-radius: 10px;
  background: #f7f7f7;/* background: #00a8db6f元の色 */
  justify-content:center; /* 子要素を垂直方向に中央揃えにする */
  width:100%;
  height: auto;
  border-bottom: 4px solid #333333;
}


.groupMemberClickShow{
  width: 8vh;
  height: 8vh;
  background-color: #e8c6c6;
  border-radius: 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  justify-content: center;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  margin-right: 2vh;
  padding: 2vh;
}

.groupMemberClickStyle {
    display: flex;
    flex-wrap: wrap; /* 複数行に折り返す */
    justify-content:center; /* 要素を均等に配置 */
    margin: 0%;
  }