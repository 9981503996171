.inputRation {
    top: 501px;
    left: 40px;
    width: 64px;
    height: 30px;
    padding: 2px 2px;
    border: 2px solid #38b289;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    font-size: 18px;
    font-family: "Zen Maru Gothic";
    text-align: center;
    margin-top: 2px;
  }
  