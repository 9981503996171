.groupNameEngEdit1 {
    font-family: "Holtwood One SC";
    font-size: 2.5rem;
    font-weight:lighter;
    margin-bottom: 0vh;
    margin-top: 0vh;
}

.groupNameJpaEdit1 {
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.675rem;
    font-weight: 600;
    font-style: normal;
    margin-top: 0vh;
}

.groupEditIntro{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
  }

.groupNameEdit{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-style: normal;
}

  .wattchaTategaki{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-align: start;
  }

  .pandding2vh{
    padding: 2vh;
  }

  .group-memberCreate1 {
    margin: 2vh; /* �v�f�Ԃ̗]����ݒ� */
    text-align: center; /* �e�L�X�g���������ɂ��� */
    align-items: center;
    width: 10vh;
    height: 10vh;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style:normal;
    border: 1px solid #000000;
    box-shadow: 1px 1px 0px rgba(0,0,0,0.8);
    padding: 0.5vh;
  }

  .margintop2vh{
    margin-top: 2vh;
  }

  .gizagiza {
    position: relative;
    width: 100%;
    height: 40px;
    background: transparent;
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 1));
}

.gizagiza::before {
    height: 40px;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
}

.gizagiza::before {
    top: -40px;
    background: linear-gradient(45deg, #fff 20px, transparent 0), linear-gradient(315deg, #fff 20px, transparent 0);
    background-size: 40px 40px;
}

.backgroundCross{
    background-color: #f3f3f3;
    opacity: 1;
    background: radial-gradient(circle, transparent 20%, #f3f3f3 20%, #f3f3f3 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #f3f3f3 20%, #f3f3f3 80%, transparent 80%, transparent) 32.5px 32.5px, linear-gradient(#444 2.6px, transparent 2.6px) 0 -1.3px, linear-gradient(90deg, #444 2.6px, #f3f3f3 2.6px) -1.3px 0;
    background-size: 65px 65px, 65px 65px, 32.5px 32.5px, 32.5px 32.5px;
}

.border{
    border: 1px solid #000000;
    width: 99%;
    border-radius: 25px;
    box-shadow: 3px 3px 0px #000000;
}