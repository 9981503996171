.linkButton{
  display: flex; /* 親要素をフレックスコンテナにする */
  align-items: center; /* 子要素を水平方向に中央揃えにする */
  justify-content :space-between; /* 子要素を垂直方向に中央揃えにする */
  padding: 2vh;
  margin-bottom: 2vh;
}

.urlButton {
  cursor: pointer;
  width: 48%;
  padding: 2vh;
  border: 1px solid #030303;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: #fbfbfb;
  color: #030303;
  box-shadow:5px 0px 0px #fa4a00;
  font-size: 14px;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}

.lineButton{
  cursor: pointer;
  width: 48%;
  padding: 2vh;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: #fbfbfb;
  border: 1px solid #030303;
  box-shadow:5px 0px 0px #01C34C;
  color: #030303;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}


