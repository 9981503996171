.group-members {
    display: flex; /* Arrange buttons in a row */
    flex-wrap: wrap; /* Wrap buttons to the next line if they overflow */
    margin: 0; /* Remove default margins */
    padding: 0; /* Remove default padding */
    list-style: none; /* Remove default list item styles */
    justify-content: center;
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    width:auto;
  }
  
  .group-member {
    margin: 10px; /* Button margin */
    cursor: pointer; /* Indicate clickable element */
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
    flex-direction: row;
    width: 90px;
    height: 90px;
    border: 6px solid #f7f853;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: -2px 2px 0px rgba(0,0,0,0.25);
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-family: "Zen Maru Gothic";
    outline: none;
    justify-content: center;
    align-items: center; /* 子要素を水平方向に中央揃えにする */
  }
  
  .group-member:hover {
    background-color: #e6e6e6; /* Hover background color */
  }
  
  .group-member.selected {
    background-color: #68d3e88d; /* Selected button background color */
    color: #000000; /* Selected button text color */
    font-weight: bolder;
  }
  
  .group-member.selected:hover {
    background-color: #68d3e88d; /* Hover background color for selected button */
  }
  
  .group-member2 {
    margin: 10px; /* Button margin */
    cursor: pointer; /* Indicate clickable element */
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
    flex-direction: row;
    width: 90px;
    height: 90px;
    border: 6px solid #f7f853;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: -2px 2px 0px rgba(0,0,0,0.25);
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-family: "Zen Maru Gothic";
    outline: none;
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    flex-direction: column;  /*子要素を縦方向に並べる */
  }
  
  .group-member2:hover {
    background-color: #e6e6e6; /* Hover background color */
  }
  
  .group-member2.selected {
    background-color: #68d3e88d; /* Selected button background color */
    color: #000000; /* Selected button text color */
    font-weight: bolder;
  }
  
  .group-member2.selected:hover {
    background-color: #68d3e88d; /* Hover background color for selected button */
  }