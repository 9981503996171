.waricanMemoryMoney {
    color: #030303;
    font-size: 1rem;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    font-style:normal;
    text-align: center;
    padding: 1.63vw;
    text-align: left;
  }
  