.memberCreateButton {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 12vw;
    height: 11vw;
    border: 1px solid #030303;
    box-sizing: border-box;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.8);
    color: #030303;
    background-color: #ffffff;
    outline: none;
    border-radius: 4px;
    margin-left: 2%;
  }
  
  .memberCreateIcon {
    color: #030303;
    fill: #030303;
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1.25rem;
  }
  