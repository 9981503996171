.inputImage{
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    flex-direction: column;
  }

  .cancelButton{
    cursor: pointer;
  width: 110px;
  height: 30px;
  padding: 0px 8px;
  border: 1px solid #030303;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
  background-color: #f7f853;
  color: #030303;
  font-size: 10px;
  font-family: "Roboto Mono";
  line-height: 16px;
  text-transform: uppercase;
  outline: none;
  }