.editButton {
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    background-color: #efebe9;
    color: #030303;
    font-size: 50px;
    font-family: "Roboto Mono";
    line-height: 16px;
    position: absolute; /* 絶対配置を許可 */
    right: 0;
    margin-right: 10px;
  }
  
  .editIcon {
    color: #000000;
    fill: #000000;
    font-size: 40px;
    width: 40px;
    height: 40px;
  }
  