.returnButton2 {
    cursor: pointer;
    width: 35vw;
    height: 13.5vw;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    background-color: #ffffff;
    color: #030303;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style:normal;
    line-height: 16px;
    text-transform: uppercase;
    outline: none;
  }
  