.waricanMemoryLocation {
  height: auto;
  min-height: 15.12%;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #000000;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 0.8rem;
  font-weight: 300;
  font-style:normal;
  text-align: center;
  padding: 1.63vw;/*3.36vw*/
  text-align: left;
  align-items: baseline;
  }

