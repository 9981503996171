.waricanMemory {
    width: auto;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #000;
    padding: 1.5vh;
    margin-top: 5%;
    border-radius: 7px;
  }
  
.waricanMemoryDisplay {
    display: flex; /* 親要素をフレックスコンテナにする */
    display: flex;
    flex-wrap: wrap; /* 複数行に折り返す */
    justify-content: space-between; /* 要素を左右均等に配置 */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    padding: 2vh;
}

.yokonarabe{
  display: flex; /* 親要素をフレックスコンテナにする */
  flex-direction:row; /* 子要素を縦方向に並べる */
  justify-content:space-between; /* 子要素を垂直方向に中央揃えにする */
}

.yokonarabe2{
  display: flex; /* 親要素をフレックスコンテナにする */
  align-items: center; /* 子要素を水平方向に中央揃えにする */
  justify-content:space-between; /* 子要素を垂直方向に中央揃えにする */
  padding: 1.63vw;
}


.waricanMemoryEditButton {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-bottom: 0%;
  padding: 0%;
}

.waricanMemoryEditButtonIcon {
  color: #030303;
  fill: #030303;
  width: 14px;
  height: 14px;
  font-size: 14px;
}

.width38{
  width: 35vw;
}

.pandding2vh{
  padding: 2vh;
}
