.searchBottun{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 1px solid #030303;
    box-sizing: border-box;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    color: #030303;
    background-color: #01a9db;
    outline: none;
}
