.sceneIcon {
    cursor: pointer;
    border: 1px solid #000;
    background: #ffffff;
    margin-right: 0.5rem;
    margin-top: 1rem;
    color: #000;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style:normal;
    border-radius: 10px;
}

  .sceneIcon.selected {
    background-color: #a6cbf4; /* 選択時：青色（例） */
  }

  .sceneState {
    display: flex;
    flex-wrap: wrap; /* 複数行に折り返す */
    justify-content: left; /* 要素を均等に配置 */
    margin: 0%;
    padding-left: 0%;
  }

  .groupMembersNameRation {
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
    height: 50%;
    vertical-align:  middle; 
  }
