.App {
  font-family: sans-serif;
  text-align: center;
}

.screenState{
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.themeEng {
  font-family: "Holtwood One SC";
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
  background: linear-gradient(transparent 60%, #38b48b 60%);
  font-weight: bold;
}

.themeJpn {
  margin-top:0;
  margin-bottom: 3vh;
  font-size: 1.5rem;
}

.themeLine{
  margin-top: 0;
  margin-bottom: 0;
  width: 315px;
  height: 1px;
  background-color: #030303;
  border-radius: 2px;
}

.groupNameLine{
  margin-top: 20px;
  margin-bottom: 0;
  height: 2px;
  background-color: #030303;
  border-radius: 2px;
  width: 500px;
}

.memberTextEng {
    font-size: 40px;
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 0;
}

.memberTextJpa {
    font-size: 30px;
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 20;
}

.groupMemberTitleLine{
  margin-top: 0;
  margin-bottom: 0;
  left: 39px;
  width: 298px;
  height: 1px;
  background-color: #030303;
  border-radius: 2px;
}

.bg_pattern {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f5f3ee;
  z-index: -1;
}
.Paper_v2 {
  background-image:
    repeating-linear-gradient(to bottom,
      transparent 50px,
      rgba(239, 6, 6, 0.04) 26px,  rgba(232, 11, 11, 0.04) 26px,
      transparent 27px,  transparent 51px, 
      rgba(0, 0, 0, 0.04) 52px,  rgba(0, 0, 0, 0.04) 52px,
      transparent 53px,  transparent 77px, 
      rgba(0, 0, 0, 0.04) 78px,  rgba(0, 0, 0, 0.04) 78px,
      transparent 79px,  transparent 103px, 
      rgba(0, 0, 0, 0.04) 104px,  rgba(0, 0, 0, 0.04) 104px,
      transparent 105px,  transparent 129px, 
      rgba(0, 0, 0, 0.04) 130px,  rgba(0, 0, 0, 0.04) 130px),
  
    repeating-linear-gradient(to right,
      transparent 25px,
      rgba(0, 0, 0, 0.04) 26px,  rgba(0, 0, 0, 0.04) 26px,
      transparent 27px,  transparent 51px, 
      rgba(0, 0, 0, 0.04) 52px,  rgba(0, 0, 0, 0.04) 52px,
      transparent 53px,  transparent 77px, 
      rgba(0, 0, 0, 0.04) 78px,  rgba(0, 0, 0, 0.04) 78px,
      transparent 79px,  transparent 103px, 
      rgba(0, 0, 0, 0.04) 104px,  rgba(0, 0, 0, 0.04) 104px,
      transparent 105px,  transparent 129px, 
      rgba(0, 0, 0, 0.04) 130px,  rgba(0, 0, 0, 0.04) 130px);
}

.backgroundcolorf5f3ee{
  background-color: #f5f3ee;
}