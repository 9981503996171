.location {
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    margin-top: 10px;
  }

  .locationBottun{
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 0px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
    font-family: "Roboto";
    line-height: 16px;
    text-align: center;
    outline: none;
  }

 
  .resultBox {
    width:auto;
    height: 36px;
    padding: 8px 8px;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
    font-family: "Roboto";
    line-height: 16px;
    text-align: left;
    outline: none;
    margin-left: 5px;
  }
  