.returnButton1 {
    cursor: pointer;
    width: 100%;
    height: 10vh;
    padding: 2vh;
    border: 1px solid #4e4e4e;
    box-sizing: border-box;
    background-color: #4e4e4e;
    color: #fff;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    font-style: normal;
  }
  