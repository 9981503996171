.CreateInfo {
    display: flex; /* 要素を横並びに配置 */
    flex-direction: column; /* 子要素を縦方向に並べる */
    align-items: center; /* 要素を垂直方向に中央揃え */
    padding: 2vh;
  }

.titleFont{
    text-align: left;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    font-style:normal;
    margin-bottom: 0%;
}

.subFont{
    text-align: left;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style:normal;
    margin-bottom: 0%;
    margin-top: 0%;
}

.subFont2{
    text-align: left;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style:normal;
    margin-bottom: 0%;
    margin-top: 0%;
}

.green{
    color: #38b48b;
}

.orange{
    color:#fa4a00;
}

.aquaBule{
    color: #00A9DB;
}

.black{
    color: #000;
}

.width100{
    width: 100%;
}

.margin3vh{
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.formmargin{
    margin-left: -43%;
}