.headericon {
    color: #030303;
    fill: #030303;
    font-size: 24px;
    width: 30px;
    height: 30px;
    margin-left: 8%;
  }
  
  .header {
    background-color: rgb(255, 255, 255);
    display: flex; /* 要素を横並びに配置 */
    justify-content:space-between; /* 要素を左右に端寄せ */
    align-items: center; /* 要素を垂直方向に中央揃え */
    border-bottom:2px solid #030303;
  }

  .wattcha{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style:normal;
    margin-right: 8%;
  }

  