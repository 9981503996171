.boxs {
  display: flex; /* 親要素をフレックスコンテナにする */
  flex-direction: column; /* 子要素を縦方向に並べる */
  justify-content:space-evenly; /* 子要素を垂直方向に中央揃えにする */
  padding: 2vh;
  }
  
  .containerbox{
    display: flex;
    border-top: 0.8px solid #000000;
    border-bottom: 0.8px solid #000000;
  }

  .containerbox2{
    display: flex;
    border-bottom: 0.8px solid #000000;
  }

  .border{
    border: 0.8px solid #000000;
    margin-right: 10%;
  }

  .margintopbottom{
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .totalAmountMemberName {
    width: 100%;
    height: 30%;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
    box-sizing: border-box;
    padding: 2vh;
    text-align: left;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .totalAmountMemberName2 {
    width: 100%;
    height: 30%;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
    box-sizing: border-box;
    padding: 2vh;
    text-align: left;
    border-left:0.8px solid #000000;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .totalAmount {
    width: 250px;
    height: 60px;
    font-size: 25px;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    font-style: normal;
    box-sizing: border-box;
    margin:0%;
    padding: 10px 0px;
  }