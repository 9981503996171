.loop-wrap {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 50px;
    background: #fff000;
    border-top: 2px solid #333333;
    border-bottom: 2px solid #333333;
  }
  .loop-area {
    display: flex;
    animation: loop-slide 20s infinite linear 1s both;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .loop-area .content {
    width: 500px;
  }
  @keyframes loop-slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  