.waricanMemoryMemberFrom {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 0.8rem;
    background-color: #ffffff;
    border-radius: 26px;
    border: 4px solid #16b5dd;
    box-sizing: border-box;
    text-align: center;
  }
  
.waricanMemoryMemberIcon{
        color: #000000;
        fill: #000000;
        width: 1.8rem;
        height: 1.8rem;
    }

.waricanMemoryMemberTo{
        width: 1.8rem;
        height: 1.8rem;
        font-size: 0.8rem;
        background-color: #ffffff;
        border-radius: 26px;
        border: 4px solid #f56d23;
        box-sizing: border-box;
    }

.waricanMemoryMemberDisplay{
        display: flex;
    }
