.paymentName {
    width: auto;
    height: 34px;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 24px;
    outline: none;
    margin-bottom: 10px;
    margin-top: 0vh;
    font-family: "Zen Maru Gothic";
    text-align: center;
  }