.oneArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1024px;
  margin: 50px auto;
  padding: 0 10px;
}
.oneArea .onebox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.oneArea .onebox:nth-child(even) {
  flex-direction: row-reverse;
  margin-top: 20px;
}

.oneArea .onebox .fukiArea {
  width: 63%;
}

.oneArea .onebox .fukidasi {
  width: 100%;
  position: relative;
  padding: 25px;
  background-color: #f2f3f7;
  font-size: 18px;
  color: #231815;
  border-radius: 12px;
  box-sizing: border-box;
  border-width:2em;
}

.oneArea .onebox .fukidasi::before {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  top: 22px;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.oneArea .onebox .fukidasi::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  top: 40px;
  border-right: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.oneArea .onebox:nth-child(odd) .fukidasi::before {
  left: -15px;
  border-left: 25px solid #f2f3f7;
  border-top: 25px solid transparent;
}
.oneArea .onebox:nth-child(odd) .fukidasi::after {
  left: -25px;
  border-left: 25px solid #ffffff;
  border-top: 25px solid transparent;
}
.oneArea .onebox:nth-child(even) .fukidasi {
  background-color: #fde5e5;
}
.oneArea .onebox:nth-child(even) .fukidasi::before {
  left: auto;
  right: -15px;
  border-left: 25px solid transparent;
  border-top: 25px solid #fde5e5;
}
.oneArea .onebox:nth-child(even) .fukidasi::after {
  left: auto;
  right: -25px;
  border-left: 25px solid transparent;
  border-top: 25px solid #ffffff;
}
@media screen and (max-width: 1024px) {
  .oneArea .onebox:nth-child(odd) .imgArea img {
    max-width: 70%;
    top: 0;
  }
  .oneArea .onebox:nth-child(even) .imgArea img {
    max-width: 70%;
    top: 0;
  }
  .oneArea .onebox .fukidasi {
    padding: 15px;
    font-size: 14px;
  }
  .oneArea .onebox .fukidasi::before {
    top: 8px;
  }
  .oneArea .onebox .fukidasi::after {
    top: 20px;
  }
}
@media screen and (max-width: 420px) {
  .oneArea {
    margin: 30px auto;
  }
  .oneArea .onebox:nth-child(even) {
    margin-top: 15px;
  }
  .oneArea .onebox .imgArea {
    width: 20%;
  }
  .oneArea .onebox .fukidasi {
    padding: 10px 15px;
    font-size: 12px;
  }
}