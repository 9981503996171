
.background38b48b{
    background-color: #38b48b;
  }

  .wattchaIntro{
    height: 160vw; /* 元々100vw */
    vertical-align: middle;
    display: flex; /* 要素を横並びに並べる */
    margin: 0%;
    padding: 0%;
    border-left: 2px solid #333333;
    border-right: 2px solid #333333;
  }

  .pannding2vh{
    padding: 2vh;
  }

  .pannding5vh{
    padding: 5vh;
  }

  .wattchaTategaki{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-align: start;
  }

  .warikanIntroFont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5rem;
  }

  .wattchaIntroState{
    display: flex; /* 要素を横並びに並べる */
    justify-content:space-between; /* 要素間のスペースを均等に分配 */
    align-items: center;
    width: 100%;
    margin: 0%;
  }

  .backgroundcolorWhite{
    background-color: #ffffff;
  }

  .warikanExplainFont{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.0rem;
    }

.textalalignstart{
    text-align: center;
}

.textalalignend{
    text-align: center;
}

.triangle1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 52px solid #555555;
  border-top: 0;
  transform: rotate(314deg);
}

.triangle2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 50px solid transparent;
    border-left: 50px solid transparent;
    border-bottom: 52px solid #555555;
    border-top: 0;
    transform: rotate(134deg);
  }

  .triangleState{
    display: flex; /* 要素を横並びに並べる */
    justify-content:space-between; /* 要素間のスペースを均等に分配 */
    align-items: center;
  }

  .IconState{
    display: flex; /* 要素を横並びに並べる */
    align-items: center;
    justify-content:space-between; /* 子要素を垂直方向に中央揃えにする */
  }

  .IconStateIcon{
    display: flex; /* 要素を横並びに並べる */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
    align-items: center;
    flex-direction: column;
  }

  .Iconfont{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    font-style: normal;
  }

  .marginright2vh{
    margin-left: 2vh;
  }


  .margintop-5vh{
    margin-top: -5vh;
  }
  .backgroundColorf6f6f6{
    background-color: #f6f6f6;
  }

  .border333333{
    border: 1px solid #333333;
  }

  .border3333333px{
    border: 3px solid #333333;
  }

  .margintop2vh{
    margin-top: 5vh;
  }

  .margintop10vh{
    margin-top: 10vh;
  }

  .border10px{
    border-radius: 10px;
  }

  .borderleftright{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .backgroundfff{
    background-color: #ffffff;
  }

  .backgroundRightStyle{
    border: 1px solid #333333;
    box-shadow: 5px 7px 0px;
  }

  .backgroundLeftStyle{
    border: 1px solid #333333;
    box-shadow: -5px 7px 0px;
  }

  .margintop5vh{
    margin-top: 5vh;
  }

  .margintop0vh{
    margin: 0vh;
  }

  .margintop2vh{
    margin-top: 5vh;
  }

  .borderradius{
    border: 1px solid #333333;
    border-radius: 70px;
  }

  .fukidashi-01-03 {
    position: relative;
    padding: 2vh;
    border-bottom: 2px solid #333333;
  }

  .fukidashi-01-03::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    background-color: #ffffff; /* 背景色と同じ色を指定 */
    rotate: 135deg;
    translate: -50%;
  }
  .fukidashi-01-03::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    z-index: -1;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #333333 #333333 transparent transparent;
    background-color: #ffffff;
    rotate: 135deg;
    translate: -50%;
  }

  .themeEngTop1{
    font-family: "Holtwood One SC";
    font-size: 2.5rem;
    font-weight:lighter;
    margin-bottom: 0vh;
    margin-top: 0vh;
  }


  .fukidashi-01-04 {
    position: relative;
    padding: 2vh;
    border-bottom: 2px solid #333333;
  }
  
  .fukidashi-01-04::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    background-color: #E37D5B; /* 背景色と同じ色を指定 */
    rotate: 135deg;
    translate: -50%;
  }
  .fukidashi-01-04::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    z-index: -1;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #333333 #333333 transparent transparent;
    background-color: #E37D5B;
    rotate: 135deg;
    translate: -50%;
  }

  .themeEngTop1{
    font-family: "Holtwood One SC";
    font-size: 2.5rem;
    font-weight:lighter;
    margin-bottom: 0vh;
    margin-top: 0vh;
  }

  .backgroundE37D5B{
    background-color: #E37D5B;
  }

 .backgroundF5F3EE{
  background-color: #F5F3EE;
 }

 .rerakeLogstate{
  display: flex; /* 要素を横並びに並べる */
  align-items: center;
}

.rirekiDatefont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  font-style: normal;
  text-align: start;
}

.rirekiTitlefont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  text-align: start;
}

.rirekiAmountfont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.0rem;
  font-weight: 600;
  font-style: normal;
  text-align: end;
}

.borderleftright5px{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid #333333;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
  width:fit-content;
}

.borderleftrighttop{
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.pannding05vh{
  padding: 1vh;
}

.pannding0{
  padding: 0vh;
}

.borderbottom{
  border-bottom: 1px solid #333333;
}

.margin0{
  margin: 0%;
}

ul.pannding2vh {
  list-style-type: none; /* リストマーカーを非表示にする */
  padding-left: 0; /* パディングを左から削除 */
}

li {
  list-style-type: none; /* 各リスト項目のマーカーも非表示にする */
}