.rationSetButtonState{
    display: flex;
}


.rationSetButton {
  cursor: pointer;
  width: 140px;
  height: 40px;
  padding: 0px 8px;
  border: 3px solid #38b289;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -2px 2px 0px rgba(0,0,0,0.25);
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-family: "Zen Maru Gothic";
  line-height: 24px;
  outline: none;
  margin-left: 10px;
}

.rationEvenButton {
  cursor: pointer;
  width: 140px;
  height: 40px;
  padding: 0px 8px;
  border: 3px solid #38b289;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -2px 2px 0px rgba(0,0,0,0.25);
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  font-family: "Zen Maru Gothic";
  line-height: 24px;
  outline: none;
  margin-right: 10px;
}

.selected {
  background-color: #68d3e88d; /* Selected button background color */
  color: #000000; /* Selected button text color */
  font-weight: bolder;
}
