.inputMoney {
    width: 300px;
    height: 40px;
    padding: 0px 8px;
    border: 4px solid #fa4a00;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #ffffff;
    color: #000000;
    font-size: 18px;
    text-align: center;
    font-family: "Zen Maru Gothic";
    line-height: 24px;
    outline: none;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  