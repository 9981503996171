.horizontal-list {
	overflow-x: auto;
	white-space: nowrap;
	width: auto;
	margin: 0%;
    padding-left: 5%;
    
}

.scroll{
    /* 横スクロール用 */
    display: inline-block;
    width: auto;
    /* 見た目調整 */
    height: auto;
    /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
    -ms-overflow-style: none;
    /*Firefoxへの対応*/
    scrollbar-width: none;
    margin-top: 5%;
    margin-right: 5%;
}

/*Google Chrome、Safariへの対応*/
.scroll::-webkit-scrollbar{
    display: none;
}

.stepBorder {
	border-right: solid 0.1875rem #000;
    padding: 0%;
}

.stepState {
    display: flex; /* 親要素をフレックスコンテナにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
}

.stepFont{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.875rem;
    font-weight: 600;
    font-style:normal;
    text-align:left;
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.stepItem1 {
	display: inline-block;
	width: 32vh;
	height: 32vh;
	font-size: 3rem;
	color: #000000;
	background-color: #e3e1e0;
    border: solid 0.1875rem #000;
    margin-right: 2vw;
}	

.stepExplainState {
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
}

.stepExplain{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 0.9375rem;
    font-weight: 300;
    font-style:normal;
    text-align: start;
}