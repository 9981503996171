.StateLogs {
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    }

.paymentLogsState {
    display:flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
    font-family: "Zen Maru Gothic";
    margin-top: 0px;
    margin-bottom: 0px;
    }

.paymentLogsState2 {
    display:flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content:flex-end; /* 子要素を垂直方向に中央揃えにする */
    font-size: 23px;
    font-family: "Zen Maru Gothic";
    margin-top: 0px;
    margin-bottom: 0px;
    }

.paymentLogsStateInputForm{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
    height: 30px;
    width: 28vw;
    text-align: center;
    border: 1px solid #333333;
}

.paymentLogsStateInputComment{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
    height: 100px;
    width: 28vw;
    text-align: center;
    border: 1px solid #333333;
}

.paymentLogStyle{
    display:flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    }

.paymentLogMember{
    display: flex; /* または display: grid */
    flex-direction: column; /* または grid-template-columns: 1fr; */
}

.paymentLogMemberFont{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    font-style: normal;
}
