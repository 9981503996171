.gaikaInput {
    cursor: pointer;
    width: auto;
    border: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #ffffff;
    color: #000000;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style:normal;
    line-height: 24px;
    outline: none;
    text-align:end;
    width: 40%;
  }