.groupNameInput {
    width: 100%;
    height: 3.5625rem;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style:normal;
    text-align: center;
    outline: none;
    margin: 2vh 0px;
  }
  