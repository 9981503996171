.step1State {
  background-color: #afdeea;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border: 1px solid #000;
  padding-top: 2vh;
  box-shadow: 4px 4px 0px rgba(0,0,0,0.8);
}

.stepInfoState {
  display: flex; /* 親要素をフレックスコンテナにする */
  align-items: center; /* 子要素を水平方向に中央揃えにする */
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border: 1px solid #000;
}

.themeEngTop1{
  font-family: "Holtwood One SC";
  font-size: 2.5rem;
  font-weight:lighter;
  margin-bottom: 0vh;
  margin-top: 0vh;
}

.themeJpnTop{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  margin:0%
}

.stepFont1{
  width: 25%;
  font-family: "Holtwood One SC";
  font-size: 1.875rem;
  font-weight: 100;
  font-style:normal;
  text-align:left;
  padding: 2vh;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  margin-bottom: 0%;
  text-align: center;
  background-color: #f0f0f0;
  color: #000000;
  box-shadow: 4px 4px 0px rgba(0,0,0,0.8);
  -webkit-text-stroke: 1px #090909;
  color: rgba(0, 0, 0, 0);
  text-shadow: 1px 1px 0px #fa4a00;
}

.fusen{
  height: auto;
  width: 100%;
  padding: 2vh;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  background-color: #fefefe;
  box-shadow: 8px 2px 0px rgba(0,0,0,0.8);
  text-align: left;
  justify-content:left; /* 子要素を垂直方向に中央揃えにする */
  margin: 2vh;
}

.noteFont{
  height: 8vh;
  width: 50vw;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-style:normal;
  text-align:left;
  text-shadow: 1px 1px 0px #38b48b;
  -webkit-text-stroke: 1px #090909;
  color: rgba(0, 0, 0, 0);
  margin:0%;
  border-bottom: 1px solid #000;
}

.fusenBox{
    width: 15vw;
    height: 30.3vh;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    box-shadow: 4px 4px 0px rgba(0,0,0,0.8);
}

.red {
  background-color: red;
}

.fusenState {
  display: flex; /* 親要素をフレックスコンテナにする */
  align-items: center; /* 子要素を水平方向に中央揃えにする */
  }

  .stepExplainMoji{
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    font-style: normal;
    margin: 2% 0px;
  }

  .step1Main {
    border: 4px solid #333;
    background: #efefef;
    padding: 4%;
    width: auto;
    margin: 10px;
    /* ↓今回のポイント！ */
    border-radius: 240px 15px 100px 15px / 15px 200px 15px 185px;
}

/* Step2のCSS*/
.fusen2 {
  height: auto;
  width: 100%;
  padding: 2vh;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  background-color: #fefefe;
  box-shadow: 4px 4px 0px rgba(0,0,0,0.8);
  text-align: left;
  justify-content: center; /* 子要素を垂直方向に中央揃えにする */
  background-color: #afdeea;
  }

.memberAddState2{
    justify-content: left; 
    margin: 2vh 0px;
}
.step2State {
  background-color: #e7afea;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  border: 1px solid #000;
  padding-top: 4vh;
  padding-bottom: 4vh;
}

.stepFont2{
  width: 25%;
  font-family: "Holtwood One SC";
  font-size: 1.875rem;
  font-weight: 300;
  font-style:normal;
  text-align:left;
  padding: 2vh;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  margin-bottom: 0%;
  text-align: center;
}

.noteFont2{
  height: 8vh;
  width: 50vw;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-style:normal;
  text-align:left;
  text-shadow: 1px 1px 0px #fa4a00;
  -webkit-text-stroke: 1px #090909;
  color: rgba(0, 0, 0, 0);
  margin: 0%;
  border-bottom: 1px solid #000;
}

.stepExplainMoji2{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  margin-top: 1vh;
}

/*noteFont*/
.noteFont3{
  height: 8vh;
  width: 50vw;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-style:normal;
  text-align:left;
  text-shadow: 1px 1px 0px #16b5dd;
  -webkit-text-stroke: 1px #090909;
  color: rgba(0, 0, 0, 0);
  margin: 0%;
  border-bottom: 1px solid #000;
}

.noteFont4{
  height: 8vh;
  width: 50vw;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-style:normal;
  text-align:left;
  text-shadow: 1px 1px 0px #F7F853;
  -webkit-text-stroke: 1px #090909;
  color: rgba(0, 0, 0, 0);
  margin: 0%;
  border-bottom: 1px solid #000;
}

.noteFont5{
  height: 8vh;
  width: 50vw;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  font-style:normal;
  text-align:left;
  text-shadow: 1px 1px 0px #090909;
  -webkit-text-stroke: 1px #090909;
  color: rgba(0, 0, 0, 0);
  margin: 0%;
  border-bottom: 1px solid #000;
}

.crateGroupNameStyle0 {
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    background-color: #ffffff;
    border-top: 2px solid #333333;
    border-bottom: 2px solid #333333;
  }

  .crateGroupNameStyle1 {
    display: flex;
    justify-content: center;
    height: auto;
    background-color: #ffffff;
    border-left: 2px solid #333333;
    border-right: 2px solid #333333;
    border-bottom: 2px solid #333333;
  }

  .crateGroupNameStyle2 {
    display: flex;
    justify-content: center;
    height: auto;
    background-color: #ffffff;
    border-left: 3px dashed #333333;
    border-right: 3px dashed #333333;
    border-bottom: 3px dashed #333333;
  }

  .crateGroupNameBox {
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    justify-content:baseline; /* 子要素を垂直方向に中央揃えにする */
    padding-bottom: 20px;
  }
  
.BoxLogo1{
    margin: 0%;
    text-align:center;
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.2rem;
    margin-bottom: 0rem;
    background: linear-gradient(transparent 60%, #fa4a00 60%);
    background-color:#fa4a00;
    font-weight:lighter;
    color: #faf7f7;
    border: 2px solid #000;
    -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
    border: 2px solid #000;
}

.BoxLogo2{
    margin-top: 10vh;
    text-align:center;
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.2rem;
    margin-bottom: 0;
    background: linear-gradient(transparent 60%, #00A9DB 60%);
    background-color: #00A9DB;
    font-weight: bold;
    border: 2px solid #000;
    
}

.BoxLogo3{
    margin-top: 10vh;
    text-align:center;
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.2rem;
    margin-bottom: 0rem;
    background: linear-gradient(transparent 60%, #5a00f6 60%);
    background-color: #5a00f6;
    font-weight: bold;
    color: #faf7f7;
    border: 2px solid #000;
    -webkit-text-stroke: 1px black;
   text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.BoxLogo4{
    margin-top: 10vh;
    text-align:center;
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.2rem;
    margin-bottom: 0;
    background: linear-gradient(transparent 60%, #38b48b 60%);
    background-color: #38b48b;
    font-weight: bold;
    border: 2px solid #000;
}

.BoxLogo5{
    margin-top: 10vh;
    text-align:center;
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.2rem;
    margin-bottom: 0;
    background: linear-gradient(transparent 60%, #F7F853 60%);
    background-color: #fffffe;
    font-weight: bold;
    border: 2px solid #000;
}

.Boxlogo{
    font-family: "Shantell Sans";
    text-align:center;
    font-size: 2rem;
    margin-top: 2vh;
    margin-bottom: 0%;
}

.Boxlogo↓{
  font-family: "Shantell Sans";
  text-align:center;
  font-size: 2rem;
  margin: 0%;
}

.crateGroupName {
    width: 50vw;
    height: 30vh;
    background-color: #afdeea;
    border-radius: 30px;
    color: #000000;
    border: 2px solid #000;
    margin-right: 3vh;
    margin: 0vh;
  }

.crateGroupboxs {
    display: flex; /* 親要素をフレックスコンテナにする */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    margin: 0%;
    }

.crateGroupNo1Title {
    font-family: "Holtwood One SC";
    color: #000000;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.4em;
    padding: 5px;
    background:#ffffff;
    writing-mode: vertical-rl;
    text-orientation: sideways;
  }

.themeCreateGroupNameEng {
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 5vh;
    font-size: 7vh;
  }
  
  .themeCreateGroupNamepn {
    margin-top:0;
    margin-bottom: 0;
    font-size: 3vh;
  }

  .themeCreateGroupExp {
    margin-top:0;
    margin-bottom: 3vh;
    font-size: 3vh;
  }

  .themeCreateGroupMember {
    font-family: "Holtwood One SC";
    margin: 0;
    font-size: 2rem;
  }

  .crateGroupScene {
    width: auto;
    height: auto;
    background-color: #cebdef;
    border-radius: 30px;
    color: #000000;
    border: 2px solid #000;
    margin-bottom: 5vh;
  }

  .sentence1 {
    width: 25vw; /* 幅 */
    height: 40vh; /* 高さ */
    flex: 0 0 auto; /* オブジェクトのサイズを自動調整 */
    margin: 0 10px; /* オブジェクト間の余白 */
    padding: 20px; /* 内側余白 */
    text-align: center; /* テキスト配置 */
  }
  .sentence2 {
    width: 35vw; /* 幅 */
    height: 40vh; /* 高さ */
    padding: 20px; /* 内側余白 */
    text-align: center; /* テキスト配置 */
    flex: 0 0 auto; /* オブジェクトのサイズを自動調整 */
    margin: 0 10px; /* オブジェクト間の余白 */
  }
  .sentence3 {
    width: 25vw; /* 幅 */
    height: 40vh; /* 高さ */
    background-color: #f0f0f0; /* 背景色 */
    flex: 0 0 auto; /* オブジェクトのサイズを自動調整 */
    margin: 0 10px; /* オブジェクト間の余白 */
    padding: 20px; /* 内側余白 */
    text-align: center; /* テキスト配置 */
  }

  .container1 {
    display: flex; /* flexboxレイアウトを有効にする */
    justify-content: center; /* オブジェクトを端から端まで配置 */
    align-items: center; /* オブジェクトを垂直方向に中央揃え */
  }

  .jpnfont1{
    font-family: "Hachi Maru Pop" ;
    font-size: 1rem;
  }

  .mannnakanioku2{
    display: flex; /* 要素を横並びに並べる */
    justify-content:center; /* 要素間のスペースを均等に分配 */
    align-items: center; /* 要素を垂直方向に中央揃え */
    margin-bottom: 50px;
    border-left: 2px solid #333333;
    border-right: 2px solid #333333;
    border-bottom: 2px solid #333333;
    padding-bottom: 30px;
    background-color: #ffffff;
}

.hidarimargin{
  margin-left: 11px;
}

.leftmargin{
  margin-left: 11px;
}

.groupmemberCreate3 {
  margin: 2vh; 
  margin-bottom: 0%;
  width: 9vh;
  height: 9vh;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px rgba(0,0,0,0.8);
  padding: 2vh;
}

.groupMemberFont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-style:normal;
  margin: 0%;
}

.backgroundColorWhite{
  background-color: #fff;
}

.backgroundColorf6f6f6{
  background-color: #f6f6f6;
}

.backgroundColorf5f6f8{
  background-color: #f5f6f8;
}

.backgroundColorfff9b1{
  background-color: #fff9b1;
}

.backgroundColor38B48B{
  background-color: #38B48B;
}

.backgroundColorE1ECFD{
  background-color: #E1ECFD;
}

.backgroundColorC4F7E3{
  background-color: #C4F7E3;
}

.backgroundColorE1ECFD{
  background-color: #E1ECFD;
}

.border-top2{
  border-top: 1px solid #000000;
}

.border-right{
  border-right: 1px solid #000;
}


.margin-top05vh{
  margin-top: 0.5vh;
}

.pandding2vh{
  padding: 2vh;
}

.margin-bottom2vh{
  margin-bottom: 10%;
}

.step5ButtonState {
  display: flex; /* 親要素をフレックスコンテナにする */
  justify-content: center; /* 子要素を垂直方向に中央揃えにする */
  margin-top: 2vh;
  }

  .submitBottun{
    cursor: pointer;
    width: 35vw;
    height: 13.5vw;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 2px 2px 0px rgba(0,0,0,0.8);
    background-color: #ffffff;
    color: #030303;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style:normal;
    text-transform: uppercase;
    outline: none;
  }