.waricanMemoryTitle {
    width:fit-content;
    max-width: 41.86vw;
    border-top: 1px solid #505050;
    border-left: 1px solid #505050;
    border-right: 1px solid #505050;
    box-sizing: border-box;
    border-radius: 6px;
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 0%;
    background-color: #ffffff;
    color: #000000;
    font-family: "hiragino-kaku-gothic-pron", sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    font-style:normal;
    text-align: center;
    padding: 1.63vw  3.36vw;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  