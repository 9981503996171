.memberTextEng1 {
    font-family: "Holtwood One SC";
    font-size: 3rem;
    padding: 0.1rem;
    margin-bottom: 0.2rem;
    background: linear-gradient(transparent 60%, #16b5dd 40%);
    font-weight: 100;
    margin-top: 5vh;
    margin-bottom: 0%;
}


.memberTextJpa1 {
    font-size: 1.5rem;
    font-family: "Holtwood One SC";
    margin-top: 0;
    margin-bottom: 2vh;
}

.groupMemberback{
    background: linear-gradient(45deg, #00A9DB, #38B48B);
    background-size: 400% 400%;
    animation: GradientBackground 10s ease infinite;
    margin-top: 0;
    display: flex; /* 親要素をフレックスコンテナにする */
    justify-content:center; /* 子要素を垂直方向に中央揃えにする */
}
.groupMemberback1{
    background-color: #faf9f3;
    width: 90%;
    margin:2vh;
    border: 2px solid #000;
}

@keyframes GradientBackground {
    0% {
      background-position: 0% 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0% 50%;
    }
  };