.waricanMemoryEditButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 0;
    box-sizing: border-box;
    border-radius: 32px;
    color: #030303;
    background-color: #f98920;
    outline: none;
  }
  
  .waricanMemoryEditButtonIcon {
    color: #030303;
    fill: #030303;
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  