.topState{
  width: auto;
  margin: 0%;
  margin-top: 2vh;
}

.h2margin{
  margin: bottom 0vh; ;
}

.bottomLine{
   background-color: #ffffff;
   margin-top: 0;
   margin-bottom: 0;
   border-top: 0.125rem solid #090909;
   border-right: 0.125rem solid #090909;
   border-left: 0.125rem solid #090909;
   border-bottom: 0.125rem solid #ffffff;
   border-top-left-radius:0.625rem;
   border-top-right-radius:0.625rem;
   padding: 4vh;
}

.memoryBack{
  background-color: #ffffff;
  z-index: -1;
}

.Polka {
  background-image: radial-gradient(#ffc107 2px, #ffffff 2px);
  background-size: 20px 20px;
}


.memoryBottunState{
  display: flex; /* 親要素をフレックスコンテナにする */
  align-items: center; /* 子要素を水平方向に中央揃えにする */
  justify-content: center; /* 子要素を垂直方向に中央揃えにする */
  padding: 2vh;
}

.startBottun{
  background-color:#F2F0E9;
  margin-top: 0vh;
}

.themeEngTop1{
  font-family: "Holtwood One SC";
  font-size: 2.5rem;
  font-weight:lighter;
  margin-bottom: 0vh;
  margin-top: 0vh;
}

.group {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Define columns */
    grid-gap: 10px; /* Add spacing between items */
  }

  .introHowTo {
    display: flex; /* 親要素をフレックスコンテナにする */
    flex-direction: column; /* 子要素を縦方向に並べる */
    align-items: center; /* 子要素を水平方向に中央揃えにする */
    justify-content: center; /* 子要素を垂直方向に中央揃えにする */
    }

    .btnlinestretches5{
        /*線の基点とするためrelativeを指定*/
      position:relative;
        /*リンクの形状*/ 
      color:#333;
        padding: 10px 0;
      display:inline-block;
        text-decoration: none;
        outline: none;
        font-size: 25px;
        margin-top: 0;
        font-family: "hiragino-kaku-gothic-pron", sans-serif;
        font-weight: 600;
    }
    
    /*線の設定*/
    .btnlinestretches5::before {
        content: "";
        /*絶対配置で線の位置を決める*/
        position: absolute;
        bottom: 0;
        /*線の形状*/
        width: 100%;
        height: 1px;
        background: #333;
        border: 2px solid #333;
    }
    
    /*矢印の設定*/
    .btnlinestretches5::after {
        content: "";
        /*絶対配置で線の位置を決める*/
        position: absolute;
        bottom:-4px;
        /*矢印の形状*/
        width: 8px;
        height: 8px;
        border-top: 4px solid #333;
        border-right: 4px solid #333;
        transform: rotate(45deg);
    }
    
    /*線と矢印を繰り返しアニメーション*/
    .btnlinestretches5::before {
        animation: arrowlong01 2s ease infinite;
    }
    .btnlinestretches5::after {
        animation: arrowlong02 2s ease infinite;
    }
    
    @keyframes arrowlong01{
        0%{width:0;opacity:0}
        20%{width:0;opacity:1}
        80%{width:105%;opacity:1}
        100%{width:105%;opacity:0}
    }
    
    @keyframes arrowlong02{
        0%{left:0;opacity:0}
        20%{left:0;opacity:1}
        80%{left:103%;opacity:1}
        100%{left:103%;opacity:0}
    }
  
.wattchaTitle{
  display: flex;
  align-items:start; 
  background-color: #ffffff;
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.tukaikatafont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  margin-top: 0vh;
}

.tukaikataIntro{
  margin-top: 5vh;
}

.tukaikataIntroMoji{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
}

.tukaikataIntroMoji2{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.675rem;
  font-weight: 600;
  font-style: normal;
  color: #000;
  
  border-bottom: solid 2px #000;
  justify-content:start; 
}

.biginfont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.875rem;
  font-weight: 600;
  font-style: normal;
  border-top:2 #090909;
}

.explainSNS{
  display: flex;
  margin: 7vh;
  align-items: center; /* 子要素を水平方向に中央揃えにする */
  justify-content: center; /* 子要素を垂直方向に中央揃えにする */
}

.explainSNSMargin{
  margin-top: 8vh;
  margin-bottom: 8vh;
}

.explainSNSColor{
  margin-top: 8vh;
  margin-bottom: 8vh;
  background-color: #F5F3EE;
  padding: 2vh;
  border-radius: 1.5rem;
  border: solid 2px#000;
}

.letsStart{
  margin: 0px;
  margin-bottom: 5vh;
  padding: 4vh;
  background-color: #38b48b;
  background-image: repeating-linear-gradient(134deg, #333333, #333333 30px, transparent 30px, transparent 60px, #333333 60px), repeating-linear-gradient(224deg, #333333, #333333 30px, transparent 30px, transparent 60px, #333333 60px), repeating-linear-gradient(-46deg, #333333, #333333 30px, transparent 30px, transparent 60px, #333333 60px), repeating-linear-gradient(44deg, #333333, #333333 30px, transparent 30px, transparent 60px, #333333 60px);
  background-size: 2px calc(100% + 86.37px), calc(100% + 86.37px) 2px, 2px calc(100% + 86.37px) , calc(100% + 86.37px) 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  animation: borderAnimation 0.9s infinite linear reverse;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
  @keyframes borderAnimation {
  from { background-position: 0 0, -86.37px 0, 100% -86.37px, 0 100%; }
  to { background-position: 0 -86.37px, 0 0, 100% 0, -86.37px 100%; }
  }

.letsStartBottunState{
  margin-top: 0vh;
}

.bottunosufont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  font-style: normal;
  margin-top: 0vh;
}


.rirekiShow{
  height: auto;
  background-color: #F5F3EE;
  margin-top: 2vh;
  border-top: solid 2px#000;
}

.zigzag-border {
  background-color: #ffffff;
  width: 100vw;
  border: solid 2px #000;
  padding: 1vh;
}

.rirekiDatefont{
  font-family: "hiragino-kaku-gothic-pron", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  margin-top: 0vh;
}